let readyScript = function () {


  $('.collection__title--arrow').on('click', function() {
    $(this).toggleClass('collection__title--active');
    return false;
  });

  $('.js-course-hide').on('click', function () {
    $(this).parent().parent().toggleClass('course--hide');
    return false;
  });
  $('body').on('click', '.reg h1', function () {
    $('.reg__tips').toggleClass('reg__tips--active');
  });
  // library video
  $('body').on('click', '.library__video-v2--js', function () {
    let src = $(this).attr('data-modal'), poster = $(this).attr('data-poster'),
      is_youtube = src.match(/youtube.com|youtu.be/) !== null;
    console.log(src);

    if (is_youtube) {
      $('#modal-video-youtube').modal('show');
      $('#modal-video-youtube iframe').attr('src', src);
    } else {
      $('#modal-video-source').modal('show');
      $('#modal-video-source video').attr('src', src.replace(/\?.+/, '')).attr('poster', poster).attr("autoplay", "autoplay");
    }
    return false;
  });
  $('#modal-video-youtube').on("hidden.bs.modal", function () {
    $('#modal-video-youtube iframe').attr('src', '');
  });
  $('#modal-video-source').on("hidden.bs.modal", function () {
    $('#modal-video-source video').attr('src', '').attr('poster', '').removeAttr("autoplay");
  });
  // news images
  $('body').on('click').on('click', '.prime__news-text-m img:not(".prime__carousel-item img")', function (e) {
    e.preventDefault();
    e.stopPropagation();
    let $container = $('<div class="js--library__pdf-images"></div>');
    let imagesThis = this
    let index = 0
    let indexClick = 0
    $(this).closest('.prime__news-item').find('.prime__news-text-m img:not(".prime__carousel-item img")').each(function () {
      //$container.append('<img src="' + $(this).attr('src') + '" />');
      if ($(imagesThis).attr('src') === $(this).attr('src')) {
        indexClick = index;
      }
      index ++;
      $container.append('<img src="' + $(this).attr('src') + '" />');
    });
    $('#modal-news-images .modal-body').append($container);
    $('.js--library__pdf-images').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: false,
      adaptiveHeight: true
    }).slick('slickGoTo', indexClick, true)
    $('#modal-news-images').modal('show');
  });
  $('#modal-news-images').on("hidden.bs.modal", function () {
    $('#modal-news-images .modal-body').empty();
  });

  // library pdf
  $('body').on('click', '.library__pdf-images--js', function (e) {
    loader.show();
    let images = $(this).attr('data-images').split(','), $container = $('<div class="js--library__pdf-images"></div>');

    preloadImages(images, function () {
      for (let i = 0, len = images.length; i < len; i++) {
        $container.append('<img src="' + images[i] + '" />');
      }

      $('#modal-pdf-images .modal-body').append($container);

      $('.js--library__pdf-images').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: false,
        adaptiveHeight: true
      });

      $('#modal-pdf-images').modal('show');
      loader.hide();
    });
    return false;
  });
  $('#modal-pdf-images').on("hidden.bs.modal", function () {
    $('#modal-pdf-images .modal-body').empty();
  });

  // burger
  $('.js-burger').on('click', function () {
    $('body').toggleClass('js-menu-show');
    return false;
  });

  $('body').on('click', '.prime__news-item-wrap, .prime__news-text-m-close', function () {
    $(this).parents('.prime__news-item').toggleClass('prime__news-item--active');
    $('.js-carousel-news:not(.slick-initialized)').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      infinite: false,
      adaptiveHeight: true
    });
    $('body').on('click', '.prime__carousel-item', function (e) {
      e.preventDefault();
      $(this).parent().prev('.js-carousel-news').slick('slickGoTo', parseInt($(this).attr('data-num')));
    })
    return false;
  });

  $('body').on('click', '.prime__ratings-tab', function () {
    $(this).toggleClass('prime__ratings-tab--active');
    return false;
  });

  $('body').on('click', '.js--video-show-desc', function () {
    $(this).parent('li').addClass('library__video-m-li--active ')
    return false
  });
  $('body').on('click', '.js--video-hide-desc', function () {
    $(this).parents('li').removeClass('library__video-m-li--active ')
    return false
  });

  console.log('Ready!');


  let $document = $(document), $body = $('body');

  // hide cookie notice
  if (typeof coreFunctions.getCookie('cookie_notice') == 'undefined') {
    $document.on('.js--cookie', 'click', function (e) {
      e.preventDefault();
      $('.cookie').hide();
      coreFunctions.setCookie('cookie_notice', 1);
    });
  }

  // фиксим модальное окно через окно, да и дальше все события вешаем на $document или $body!!!
  $document
    .on('hidden.bs.modal', function (event) {
      if ($('.modal:visible').length) {
        $('body').addClass('modal-open');
      }
    })
    .on('click', '*[data-bs-target="#modal--check-download"]', function (event) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({'event': 'intentUploadCheck'});
      }
    })
    .on('click', '*[data-gtagcategory]', function (e) {
      // gtag
      var data = $(this).data();
      if (typeof gtag === 'undefined') {
        return false;
      }
      gtag('event', 'click', {
        'event_category': data.gtagcategory,
        'event_label': data.gtaglabel
      });
    })
    .on('change', '#reg_user_phone', function (e) {
      $('#check_code_user_phone').val($(this).val());
    })
    .on('click', '.js--check_phone_btn', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('#js--form__phone_confirm').trigger('submit');
    })
    .on('change', '#reg_user_work_position', function (e) {
      let $t = $(this), office = $t.data('office'), shops = $t.data('shops'), $city = $('#reg_user_shop_city');
      //
      //   if ($t.val() === 'Сотрудник офиса') {
      //     $city.data('shops', office);
      //   } else {
      //     $city.data('shops', shops);
      //   }
      //
      //   $city.find('option:gt(0)').remove();
      //   for (let s in $city.data('shops')) {
      //     $city.append('<option value="' + s + '">' + s + '</option>');
      //   }
      //

      $city.trigger('change');
      $city.val('');
    })
    .on('change', '#reg_user_shop_city', function (e) {
      let city = $(this).val(), shops = $(this).data('shops'), $name = $('#reg_user_shop_name');

      $name.find('option:gt(0)').remove();

      let select = $name.get(0);
      for (let k in shops[city]) {
        let option = document.createElement("option");
        option.text = k;
        option.value = k;
        select.appendChild(option);
      }

      $name.removeAttr('disabled');
      $name.trigger('change');
      $name.val('');
    })
    .on('change', '#reg_user_shop_name', function (e) {
      let $city = $('#reg_user_shop_city'), city = $city.val(), shops = $city.data('shops'),
        name = $(this).val(), $format = $('#reg_user_shop_format');

      // if (typeof shops[city] == 'undefined' || typeof shops[city][name] == 'undefined') {
      //   return;
      // }

      $format.find('option:gt(0)').remove();
      let select = $format.get(0);
      for (let k of shops[city][name]) {
        let option = document.createElement("option");
        option.text = k;
        option.value = k;
        select.appendChild(option);
      }

      $format.removeAttr('disabled');
      $format.trigger('change');
      $format.val('');
    })
    .on('change', '.exam__body input', function (e) {
      let $b = $(this).closest('.exam__body'), $checked = $b.find('input:checked'), $next = $b.find('.js--btn__next');
      if ($checked.length) {
        $next.attr('disabled', false).removeAttr('disabled');
      } else {
        $next.attr('disabled', 'disabled');
      }
    })
    .on('click', '.exam__body .js--btn__prev', function (e) {
      let $b = $(this).closest('.exam__body'), $prev = $b.prev('.exam__body');
      if ($prev.length) {
        $b.addClass('d-none');
        $prev.removeClass('d-none');
        e.preventDefault();
      }
    })
    .on('click', '.exam__body .js--btn__next', function (e) {
      let $b = $(this).closest('.exam__body'), $next = $b.next('.exam__body');

      // send to crm
      loader.enabled = false;
      $b.find('a').trigger('click');

      // next
      if ($next.length) {
        $b.addClass('d-none');
        $next.removeClass('d-none');
        e.preventDefault();
      } else {
        // submit
      }
    })
    .on('click', '.js-toggle-password', function (e) {
      let $t = $(this), $input = $t.closest('div').find('.eye');

      if ($t.hasClass('fa-eye-slash')) {
        $t.removeClass('fa-eye-slash');
        $input.attr('type', 'password');
      } else {
        $t.addClass('fa-eye-slash');
        $input.attr('type', 'text');
      }
    });

  // Профиль
  (function () {
    // Магазины в профиле
    let $city = $('#reg_user_shop_city'), $name = $('#reg_user_shop_name'), $format = $('#reg_user_shop_format');

    let changeName = function () {
      let selected = $format.data('selected');
      if (selected) {
        $format.find('option').each(function () {
          let $t = $(this);
          if (this.value === selected) {
            $t.attr('selected', 'selected');
            $t.trigger('change');
          }
        });
      }
    };

    let changeCity = function () {
      let selected = $name.data('selected');
      if (selected) {
        $name.find('option').each(function () {
          let $t = $(this);
          if (this.value === selected) {
            $t.attr('selected', 'selected');
            $t.trigger('change');
            setTimeout(changeName, 100);
          }
        });
      }
    };

    if ($city.val()) {
      $city.trigger('change');
      setTimeout(changeCity, 100);
    }

    // обновление личных данных
    $('body').on('click', '.lk__personal-change-password', function (e) {
      e.preventDefault();
      $('.js--form__change__password').toggleClass('d-none');
    });
  })();

  // таймер экзамена
  (function () {
    let $t = $('.timer[data-time-left]');
    if ($t.length < 1) {
      return;
    }

    let timer = setTimeout(function tick() {
      if ($t.data('time-stop')) {
        return false;
      }
      let sec = $t.data('time-left'), secVal = parseInt(sec, 10), tpl;

      if (secVal > -1) {
        let m = parseInt(secVal / 60, 10),
          s = secVal - m * 60,
          mm = '' + (m > 9 ? m : '0' + m),
          ss = '' + (s > 9 ? s : '0' + s);

        tpl = '<span ref="minutes">' + mm + '</span>:<span ref="seconds">' + ss + '</span>';

        secVal--;
        $t.data('time-left', secVal);
        $t.attr('data-time-left', secVal);

        timer = setTimeout(tick, 1000);
      } else {
        tpl = '<span ref="minutes">00</span>:<span ref="seconds">00</span>';
        // отправляем принудительно форму с ответами
        $('.js--test_answers__form').trigger('submit');
      }
      $t.html(tpl);
    }, 1000);

    let iv = setInterval(function () {
      loader.enabled = false;
      $.get($t.attr('data-server-time-left'), function (secVal) {
        loader.enabled = true;
        $t.data('time-left', secVal);
        $t.attr('data-time-left', secVal);
        if (parseInt(secVal, 10) < 1) {
          clearInterval(iv);
        }
      });
    }, 1000 * 30); // раз в N-секунд запрашиваем обновление времени с сервера

  })();

  // // чтобы не нажимать много раз
  // (function () {
  //   $('.exam__body').each(function (i) {
  //     let $t = $(this), inpid = Math.floor(Math.random() * 4);
  //     $t.find('input[type=radio]:eq(' + inpid + ')').trigger('click');
  //     (function ($b){
  //       setTimeout(function (){
  //         $b.find('.js--btn__next').trigger('click');
  //       }, i * 200);
  //     })($t);
  //   });
  // })();

  // events on body
  $body.addClass('loaded');

  // подтверждение аккаунта
  (function () {
    let confirmed = coreFunctions.getParameterByName("confirmed");
    if (confirmed !== '') {
      window.setTimeout(function () {
        $('#modal--registration-congratulation').modal('show');
        coreFunctions.setHistoryState(document.title, "/");
      }, 50);
    }
  })();

  // inputmask
  $('input[data-mask]').each(function () {
    var $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  // scroll
  $('.js--scroll').on('click', function () {
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 400);
    return false
  });

  // datepicker
  $.datepicker.regional['ru'] = {
    closeText: 'Закрыть',
    prevText: 'Предыдущий',
    nextText: 'Следующий',
    currentText: 'Сегодня',
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    weekHeader: 'Не',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    changeMonth: true,
    changeYear: true,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['ru']);

  $.datepicker._generateMonthYearHeader_original = $.datepicker._generateMonthYearHeader;

  $.datepicker._generateMonthYearHeader = function (inst, dm, dy, mnd, mxd, s, mn, mns) {
    var header = $($.datepicker._generateMonthYearHeader_original(inst, dm, dy, mnd, mxd, s, mn, mns)),
      years = header.find('.ui-datepicker-year');

    // reverse the years
    years.html(Array.prototype.reverse.apply(years.children()));

    // return our new html
    return $('<div />').append(header).html();
  };

  $('.datepicker').each(function () {
    let $t = $(this);
    $t.datepicker({
      yearRange: $t.attr('data-year-range'),
    });
  });

  // message
  window.showBootstrapMessage = function (params) {
    params = $.extend({
      title: '',
      message: '',
      close: 'ОК'
    }, params);

    let $v_modal = $('.modal:visible'),
      $m_modal = $('#modal-message'),
      $title = $m_modal.find('.modal-title'),
      $message = $m_modal.find('.js-modal-content'),
      $close = $m_modal.find('button.btn');

    $v_modal.modal('hide');
    $('.modal-backdrop').remove();

    $title.html(params.title);
    $message.html('<p>' + params.message + '</p>');
    $close.html(params.close);

    $m_modal.modal('show');

    if (typeof params['afterHide'] === 'function') {
      $m_modal.on('hidden.bs.modal', function () {
        $m_modal.off('hidden.bs.modal');
        params.afterHide.call();
      });
    }
  };

  // modal
  window.showBootstrapModal = function (selector) {
    $('.modal:visible').modal('hide');
    $('.modal-backdrop').remove();
    $(selector).modal('show');
  };
};

$(document).ready(readyScript);
$(document).on('page:load', readyScript);

function preloadImages(urls, allImagesLoadedCallback) {
  var loadedCounter = 0;
  var toBeLoadedNumber = urls.length;
  urls.forEach(function (url) {
    preloadImage(url, function () {
      loadedCounter++;
      console.log('Number of loaded images: ' + loadedCounter);
      if (loadedCounter == toBeLoadedNumber) {
        allImagesLoadedCallback();
      }
    });
  });

  function preloadImage(url, anImageLoadedCallback) {
    var img = new Image();
    img.onload = anImageLoadedCallback;
    img.src = url;
  }
}
